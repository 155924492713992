import styled from 'styled-components';

import ImgBgPremium from "../../Images/BgPremium.jpg";

export const BgPremium = styled.section`
    background-image: url(${ImgBgPremium});
    display: flex;
    background-position: center;
    background-size: cover;

    @media (max-width: 600px) {
        padding-top: 10px;
        padding-bottom: 0px;
    }
`;

export const ForaPremium = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 6vw;
    padding-bottom: 6vw;

    @media (max-width: 600px) {
        padding-top: 3vw;
    }
`;

export const CimaPremium = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 175px
    }

    .TextPremium1 {
        font-size: 37px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 15px;
    }    
    
    .ButtonPremium {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #c59439;
        border-radius: 8px;
        width: 410px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        cursor: pointer;
    }

    @media (max-width: 1400px) {

        .TextPremium1 {
            font-size: 34px;
            margin-bottom: 25px;
        }
    }    
    
    @media (max-width: 900px) {

        .TextPremium1 {
            font-size: 27px;
            margin-bottom: 15px;
            margin-top: 10px;
        }

        .ButtonPremium {
            width: 350px;
            height: 38px;
            font-size: 19px;
        }
    }    
    
    @media (max-width: 600px) {
        img {
            width: 130px;
            padding-bottom: 0px;
        }

        .TextPremium1 {
            font-size: 20px;
            text-align: center;
        }

        .ButtonPremium {
            width: 350px;
            height: 38px;
            font-size: 19px;
        }
    }
`;

export const BaixoPremium = styled.div`
    padding-bottom: 60px;
    background-color: #111111;
    width: 90%;
    margin: 0 auto;

    .SliderBanner1 {
        width: 90%;
        margin: 0 auto;
    }

    @media (max-width: 650px) {
        padding-bottom: 30px;
    }

    
`;