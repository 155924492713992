/* eslint-disable react/prop-types */
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as S from './estilos';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgIconShows from '../../Images/IconShows.png';
import ImgItemPremium from '../../Images/ImgItemPremium.jpg';
import ImgAmbientes from '../../Images/ImgAmbientes.jpg';
import ImgAmbientesResp from '../../Images/ImgAmbientesResp.jpg';
import ImgSliderAmbientes1 from '../../Images/ImgSliderAmbientes1.jpg';
import ImgSliderAmbientes2 from '../../Images/ImgSliderAmbientes2.jpg';
import ImgSliderAmbientes3 from '../../Images/ImgSliderAmbientes3.jpg';
import ImgSliderAmbientes4 from '../../Images/ImgSliderAmbientes4.jpg';

function Ambientes(props: any) {
  const [ambiente, setAmbiente] = useState(3);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div id='Ambientes' style={{marginTop: "-100px", position: "absolute"}}></div>
      <S.BgAmbientes>
        <S.ForaAmbientes>
          <S.TopShows>
              <div className='RiskShows'></div>
              <img alt='Icon' src={ImgIconShows} />
              <div className='TextShows'>Ambientes da Casa</div>
              <img alt='Icon' src={ImgIconShows} />
              <div className='RiskShows'></div>
          </S.TopShows> 

          <S.TopAmbientes>
            <a style={ambiente === 3 ? {borderBottom: "3px solid #fc551e"} : {}} onClick={() => setAmbiente(3)}>Praça do Cavalo</a>
            <a style={ambiente === 1 ? {borderBottom: "3px solid #fc551e"} : {}} onClick={() => setAmbiente(1)}>Saloon</a>
            <a style={ambiente === 4 ? {borderBottom: "3px solid #fc551e"} : {}} onClick={() => setAmbiente(4)}>Praça Sertaneja</a>
          </S.TopAmbientes>

          {ambiente == 1 && // Saloon
            <S.ForaImgAmbientes>
              <img alt='Foto do ambiente Saloon no Villa Country' src={ImgSliderAmbientes1} />
              <div className='ForaTextAmbientes'>
                <div className='TextAmbientes1'>Saloon</div>
                <div className='TextAmbientes2'>
                  O Saloon é o coração pulsante da Villa Country! Com capacidade para mais de mil pessoas, 
                  é o palco que impulsionou muitos artistas e reúne uma vibe incrível para quem curte uma noite 
                  agitada! Com banda completa ao vivo, é o lugar perfeito para quem gosta de curtir muito e dançar até o amanhecer! 
                </div>
              </div>
            </S.ForaImgAmbientes>
          }                 
          
          {ambiente == 3 && // Praça do Cavalo  
            <S.ForaImgAmbientes>
              <img alt='Foto do ambiente Praça do Cavalo no Villa Country' src={ImgSliderAmbientes3} />
              <div className='ForaTextAmbientes'>
                <div className='TextAmbientes1'>Praça do Cavalo</div>
                <div className='TextAmbientes2'>
                  A Praça do Cavalo é o primeiro ambiente que você vê quando entra na casa. 
                  Com bar em forma de ferradura, expõe uma imensa estátua de montaria em bronze. 
                  Na lateral, um cenário típico do western, com uma velha mina e um carroção, completa a decoração. 
                  É o ambiente perfeito para o seu esquenta. 
                </div>
              </div>
            </S.ForaImgAmbientes>
          }          
          
          {ambiente == 4 && // Praça Sertaneja
            <S.ForaImgAmbientes>
              <img alt='Foto do ambiente Praça Sertaneja no Villa Country' src={ImgSliderAmbientes4} />
              <div className='ForaTextAmbientes'>
                <div className='TextAmbientes1'>Praça Sertaneja</div>
                <div className='TextAmbientes2'>
                  A cultura sertaneja nacional está presente em todos os aspectos desse ambiente, a começar pelo teto de sapê. 
                  A praça, também conhecida como Praça do Chafariz, reproduz com fidelidade o clima de fazenda, não só na decoração, 
                  mas nos quitutes que oferece. Nesse espaço acontecem shows e canjas de muitos artistas e os clientes contemplam um ambiente intimista com voz e violão. 
                </div>
              </div>
            </S.ForaImgAmbientes>
          }

          {/* 
          <Slider className="SliderBanner1" {...settings}>
              <img src={ImgSliderAmbientes} />
              <img src={ImgSliderAmbientes} />
              <img src={ImgSliderAmbientes} />
              <img src={ImgSliderAmbientes} />
              <img src={ImgSliderAmbientes} />
          </Slider>  
          */}

          <div id='Restaurante' style={{marginTop: "-100px", position: "absolute"}}></div>

          <a href='/Restaurante'>
            <img alt='Banner restaurante John Wayne' className='ImgBaixoAmbientes' src={ImgAmbientes} />
            <img alt='Banner restaurante John Wayne' className='ImgBaixoAmbientes ImgBaixoAmbientesResp' src={ImgAmbientesResp} />
          </a>
        </S.ForaAmbientes>     
      </S.BgAmbientes>
    </>
  );
}

export default withRouter(Ambientes);
