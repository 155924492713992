/* eslint-disable import/first */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

const endpoint = process.env.REACT_APP_ENDPOINT;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgPremium from "../../Images/ImgPremium.png";
import ImgItemPremium from "../../Images/ImgItemPremium.jpg";

function Premium(props: any) {
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}/promocoes.php`);
        setLoad(true);
        if (!response.ok) {
          throw new Error("Não foi possível obter os dados da API");
        }
        const data = await response.json();
        setDados(data);
        setTimeout(() => {
          setLoad(false);
        }, 2000);
      } catch (error) {
        console.error("Erro ao buscar os dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {load == true && <Load status={true} />}
    {/*
      <div
        id="Premium"
        style={{ marginTop: "-100px", position: "absolute" }}
      ></div>
      <S.BgPremium>
        <S.ForaPremium>
          <S.CimaPremium>
            <img alt="Villa Premium" src={ImgPremium} />
            <div className="TextPremium1">Promoções exclusivas pra você</div>
            <a href="#App" className="ButtonPremium">
              Cadastre-se gratuitamente
            </a>
          </S.CimaPremium>
        </S.ForaPremium>
      </S.BgPremium>
      */}
      <S.BaixoPremium>
        <Slider className="SliderAniversario" {...settings}>
          {dados.map((item: any, index) => (
            <a target="_blank" rel="noreferrer" href={item.c_link} key={index}>
              <img
                alt="Banner Evento"
                src={`https://appvillacountry.com.br/uploadnew/premium/${item.id_tb_premium}c.${item.c_extensao2}`}
              />
            </a>
          ))}
        </Slider>

        <Slider className="SliderAniversarioResp" {...settings}>
          {dados.map((item: any, index) => (
            <a target="_blank" rel="noreferrer" href={item.c_link} key={index}>
              <img
                alt="Banner Villa Premium"
                src={`https://appvillacountry.com.br/uploadnew/premium/${item.id_tb_premium}d.${item.c_extensao3}`}
              />
            </a>
          ))}
        </Slider>
      </S.BaixoPremium>
       
    </>
  );
}

export default withRouter(Premium);
