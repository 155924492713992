/* eslint-disable react/prop-types */
import { withRouter } from 'react-router-dom';
import * as S from './estilos';

import ImgEvento from '../../Images/ImgEvento.jpg';
import ImgEventoResp from '../../Images/ImgEventoResp.jpg';

function FacaEventos(props: any) {

  return (
    <>
      <S.BgEventos>
        <S.ForaEventos>
          <a href='https://www.gruposaopauloeventos.com.br/villacountry/' target='_blank'><img alt='Imagem para eventos Villa Country' className='ImgEventos' src={ImgEvento} /></a>
          <a href='https://www.gruposaopauloeventos.com.br/villacountry/' target='_blank'><img alt='Imagem para eventos Villa Country' className='ImgEventosResp' src={ImgEventoResp} /></a>
        </S.ForaEventos>
      </S.BgEventos>
    </>
  );
}

export default withRouter(FacaEventos);
