import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";

declare global {
  interface Window {
    opera?: any;
  }
}

function Aplicativo(props: any) {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.open("https://play.google.com/store/apps/details?id=com.villacountrypremium");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open("https://apps.apple.com/app/villa-country-premium/id6502418207");
    } else {
      document.location = "https://www.villacountry.com.br";
    }
  }, []);

  return <></>;
}

export default Aplicativo;
