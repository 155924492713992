/* eslint-disable react/prop-types */
import { withRouter } from 'react-router-dom';
import * as S from './estilos';

import ImgIconShows from '../../Images/IconShows.png';
import ItemNoVila1 from '../../Images/ItemNoVila1.jpg';
import ItemNoVila2 from '../../Images/ItemNoVila2.jpg';
import ItemNoVila3 from '../../Images/ItemNoVila3.jpg';
import ItemNoVila4 from '../../Images/ItemNoVila4.jpg';
import ItemNoVila5 from '../../Images/ItemNoVila5.jpg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function VoceNoVila(props: any) {

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },      
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      }
    ],
  };

  return (
    <>
      <div id='Fotos' style={{marginTop: "-100px", position: "absolute"}}></div>
      <S.ForaNoVila>
        <S.TopShows>
            <div className='RiskShows'></div>
            <img alt='Icon' src={ImgIconShows} />
            <div className='TextShows'>Você no vila</div>
            <img alt='Icon' src={ImgIconShows} />
            <div className='RiskShows'></div>
        </S.TopShows> 
        <S.ContentNoVila>
          <Slider className="SliderBanner1" {...settings}>
            <div className='ItemNoVila'>
              <img src={ItemNoVila1} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila2} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila3} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila4} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila5} />
            </div>               
            <div className='ItemNoVila'>
              <img src={ItemNoVila1} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila2} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila3} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila4} />
            </div>             
            <div className='ItemNoVila'>
              <img src={ItemNoVila5} />
            </div>             
          </Slider>
        </S.ContentNoVila>     
      </S.ForaNoVila>
    </>
  );
}

export default withRouter(VoceNoVila);
