import styled from 'styled-components';
import ImgBgRedesBaixo from '../../Images/BgRedesBaixo.jpg';
import ImgBgSite from "../../Images/BgSite.jpg";

export const BgRedes = styled.section`
    background-image: url(${ImgBgSite});
    background-repeat: repeat;
  
    .slick-slide img {
        width: 100%;
    }

    @media (max-width: 500px) {
        .slick-slide img {
            width: 225px;
            margin: 0 auto;
        }
    }
`;

export const ForaRedes = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        font-weight: bold;
        font-size: 35px;
        text-transform: uppercase;
        margin-top: 80px;
        margin-bottom: 40px;
    }

    .SliderBanner2 {
        width: 325px;
    }

    .slick-prev {
        display: none !important;
    }    
    
    .slick-next {
        display: none !important;
    } 
    
    @media (max-width: 650px) {
        h2 {
            margin-top: 35px;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 450px) {
        .SliderBanner2 {
            width: 90%;   
        }
    }
`;

export const ForaImgRedes = styled.div`
    width: 580px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;  

    @media (max-width: 1400px) {
        width: 410px;

        a img {
            width: 75px;
        }
    }
    
    @media (max-width: 620px) {
        flex-wrap: wrap;
        width: 90%;
        justify-content: space-around;

        a {
            padding: 0% 5%;
            margin-top: 10px;
        }

        img {
            height: 75px;
        }
    }
`;

export const TopShows = styled.div`
    font-family: 'Poppins', sans-serif;
    color: #fc551e;
    width: 550px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 40px;

    .RiskShows {
        width: 60px;
        height: 2px;
        background-color: #fc551e;
    }

    .TextShows {
        font-weight: bold;
        font-size: 25px;
    }

    @media (max-width: 650px) {
        width: 95%;
        padding-top: 0px;
        padding-bottom: 15px;

        .TextShows {
            font-size: 18px;
        }

        .RiskShows {
            width: 30px;
        }
    }
`;

export const BgRedesBaixo = styled.div`
    background-image: url(${ImgBgRedesBaixo});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 55px;
    margin-top: 30px;

    @media (max-width: 520px) {
        margin-top: 45px;
    }
`;


