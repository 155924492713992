/* eslint-disable react/prop-types */
import { withRouter } from 'react-router-dom';
import * as S from './estilos';

import ImgApp1 from '../../Images/ImgApp1.jpg';
import ImgApp2 from '../../Images/ImgApp2.jpg';

function App(props: any) {


  return (
    <>
      {/*
      <div id='App' style={{marginTop: "-100px", position: "absolute"}}></div>
      <S.BgApp>
        <S.ForaApp>
          <img alt='Imagem app villa Country' className='ImgApp' src={ImgApp1} />
          <img alt='Imagem app Villa Country' className='ImgAppResp' src={ImgApp2} />
        </S.ForaApp>
      </S.BgApp>
       */}
    </>
  );
}

export default withRouter(App);
