import styled from 'styled-components';
import ImgBgHeader from "../../Images/BgHeader.jpg";
import ImgBgBanner from "../../Images/BgBanner.jpg";
// export const BackgroundHeader = styled.header`
//   background-color: ${({ theme }) => theme.color.black};
//   background-color: ${({ theme }) => theme.color.primary};
//   position: fixed;
//   width: 100%;
//   z-index: 2;
// `;

export const BgHeader = styled.header`
    background-image: url(${ImgBgHeader});
    height: 100px;
    display: flex;
    background-position: center;
    background-size: cover;
    position: fixed;
    z-index: 1000;
    width: 100%;

    section {
        display: flex;
        width: 93%;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
    }

    .ImgLogo {
        width: 90px;
    }
`;

export const ForaMenu = styled.div`
    display: flex;
    width: 1140px;
    justify-content: space-between;
    height: 26px;
    
    a {
        color: #b9b9b9;
        font-weight: bold;
        font-size: 15px;
        cursor: pointer;
        text-decoration: none;
    }

    a:hover {
        border-bottom: 3px solid #fc551e;
    }

    @media (max-width: 1600px) {
        width: 1010px;

        a {
            font-size: 14px;
        }
    }   
    
    @media (max-width: 1310px) {
        width: 880px;

        a {
            font-size: 13px;
        }
    }

    @media (max-width: 1140px) {
        display: none;
    }
`;

export const ForaRedes = styled.div`
    display: flex;
    width: 170px;
    justify-content: space-between;

    img {
        width: 28px;
        cursor: pointer;
    }

    @media (max-width: 1600px) {
        width: 135px;
    }   
    
    @media (max-width: 1390px) {
        width: 70px;
        flex-wrap: wrap;
        height: 65px;
        align-items: center;

        img {
            width: 23px;
        }
    }    

    @media (max-width: 1140px) {
        width: 170px;
    }
`;

export const ForaBotao = styled.div`
    display: none;
    flex-direction: column;
    height: 32px;
    justify-content: space-between;
    width: 45px;
    cursor: pointer;

    div {
        background-color: #fc551e;
        height: 4px;
        width: 100%;
        border-radius: 2px;
    }

    @media (max-width: 1140px) {
        display: flex;
    }
`;




export const BgMenuMobile = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000;
    position: fixed;
    z-index: 1001;
    display: flex;
    align-items: center;

    .XMobile {
        font-weight: bold;
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 25px;
        cursor: pointer;
    }

    .ForaMenuMobile {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        color: #fc551e;
        text-transform: uppercase;
        font-size: 35px;
        margin-bottom: 20px;
    }
`;

export const ItensMenuMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        font-size: 23px;
        padding-top: 10px;
        cursor: pointer;
    }
`;

export const ForaRedesMenuMobile = styled.div`
    width: 200px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
