/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import * as S from "./estilos";

import Load from "../Load";

import ImgIconShows from "../../Images/IconShows.png";

function Shows(props: any) {
  const [dados, setDados] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const isVisibleNext = useRef(true);
  const category = useRef("");

  const loadData = () => {
    const apiUrl = `https://appvillacountry.com.br/api/programacao.php?page=${page}`;
    setLoad(true);
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Não foi possível obter os dados da API");
        }
        return response.json();
      })
      .then((newData) => {
        setLoad(false);
        if (newData.length < 6) {
          isVisibleNext.current = false;
        }
        if (page === 1) {
          setDados(newData);
        } else {
          setDados((prevData) => [...prevData, ...newData]);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar os dados da API:", error);
      });
  };

  useEffect(() => {
    loadData();
  }, [page]);

  const loadMoreShows = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {load && <Load status={true} />}
      <div id="Programacao" style={{ marginTop: "-100px", position: "absolute" }}></div>
      <S.BgShows>
        <S.ForaShows>
          <S.TopShows>
            <div className="RiskShows"></div>
            <img alt="Icon" src={ImgIconShows} />
            <div className="TextShows">Shows em destaque</div>
            <img alt="Icon" src={ImgIconShows} />
            <div className="RiskShows"></div>
          </S.TopShows>

          <S.ContentShows>
            {dados.map((item: any, index) => (
              <S.ItemShows
                target="_blank"
                rel="noreferrer"
                key={index}
                href={item.c_link_vendas ? item.c_link_vendas : undefined}>
                <div
                  className="FotoShows"
                  style={{
                    backgroundImage:
                      "url('https://appvillacountry.com.br/uploadnew/agenda/" +
                      item.id_tb_agenda +
                      "." +
                      item.c_extensao +
                      "')",
                  }}
                />
                <div className="BaixoShows">
                  <div className="TextShows1">{item.c_titulo}</div>
                  {item.c_link_vendas !== "" && <div className="ButtonIngressos">Ingressos</div>}
                  <div className="TextShows2">{item.c_data_extenso}</div>
                  <div className="TextShows3">{item.c_local}</div>
                </div>
              </S.ItemShows>
            ))}
          </S.ContentShows>
          {isVisibleNext.current && (
            <a onClick={() => loadMoreShows()} className="VerShows">
              ver mais shows
            </a>
          )}
        </S.ForaShows>
      </S.BgShows>
    </>
  );
}

export default withRouter(Shows);
