import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const endpoint = process.env.REACT_APP_ENDPOINT;

interface BannerProps extends RouteComponentProps {
  visibilidade?: boolean; // Torna a prop opcional
}

const Banner: React.FC<BannerProps> = ({ visibilidade = true }) => { 
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}/banners.php`);
        setLoad(true);
        if (!response.ok) {
          throw new Error("Não foi possível obter os dados da API");
        }
        const data = await response.json();
        setDados(data);

        setLoad(false);
      } catch (error) {
        console.error("Erro ao buscar os dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <S.ForaBanner id="Home">
        <Slider className="SliderBanner1" {...settings}>
          {dados.map((item: any, index) => (
            <a target="_blank" rel="noreferrer"  {...item.c_link !== "" ? { href: item.c_link } : null} key={index}>
              <img
                alt="Banner Evento"
                src={`https://appvillacountry.com.br/uploadnew/banner_novo/${item.id_tb_banner}a.${item.c_extensao}`}
              />
              <S.ForaInfoBanner>
                <S.ForaContentBanner>
                  <S.TextBanner>
                    {item.c_titulo} <span>{item.c_texto}</span>
                  </S.TextBanner>
                  {item.c_link !== "" && <button>Ingressos</button> }
                </S.ForaContentBanner>
              </S.ForaInfoBanner>
            </a>
          ))}
        </Slider>
      </S.ForaBanner>

      <S.ForaBannerResp id="Home">
        <Slider className="SliderBanner1" {...settings}>
          {dados.map((item: any, index) => (
            <a target="_blank" rel="noreferrer" href={item.c_link} key={index}>
              <img
                alt="Banner Evento"
                src={`https://appvillacountry.com.br/uploadnew/banner_novo/${item.id_tb_banner}b.${item.c_extensao1}`}
              />
              <S.ForaInfoBanner>
                <S.ForaContentBanner>
                  <S.TextBanner>
                    {item.c_titulo} <span>{item.c_texto}</span>
                  </S.TextBanner>
                  {item.c_link !== "" && <button>Ingressos</button> }
                </S.ForaContentBanner>
              </S.ForaInfoBanner>
            </a>
          ))}
        </Slider>
      </S.ForaBannerResp>
    </>
  );
};

export default withRouter(Banner);
