import styled from 'styled-components';
import ImgBgSite from "../../Images/BgSite.jpg";
import ImgBgPremium from "../../Images/BgPremium.jpg";

export const BgAmbientes = styled.section`
    background-image: url(${ImgBgSite});
    background-repeat: repeat;

    @media (max-width: 600px) {
    }
`;


export const TopShows = styled.div`
    font-family: 'Poppins', sans-serif;
    color: #fc551e;
    width: 550px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 25px;

    .RiskShows {
        width: 60px;
        height: 2px;
        background-color: #fc551e;
    }

    .TextShows {
        font-weight: bold;
        font-size: 25px;
    }

    @media (max-width: 650px) {
        width: 95%;
        padding-top: 30px;
        padding-bottom: 15px;

        .TextShows {
            font-size: 18px;
        }

        .RiskShows {
            width: 30px;
        }
    }
`;

export const ForaAmbientes = styled.div`
    width: 1280px;
    margin: 0 auto;

    .ImgBaixoAmbientes {
        width: 100%;
        margin-top: 50px;
    }    
    
    .ImgBaixoAmbientesResp {
        display: none;
    }

    @media (max-width: 1340px) {
        width: 90%;
    }   
    
    @media (max-width: 900px) {
        .ImgBaixoAmbientes {
            margin-top: 25px;
            display: none;
        }

        .ImgBaixoAmbientesResp {
            display: block;
        }
    }
`;

export const TopAmbientes = styled.div`
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 50px;
    height: 29px;

    a {
        font-size: 20px;
        font-weight: bold;
        color: #b9b9b9;
        text-transform: uppercase;
        cursor: pointer;
    }

    @media (max-width: 900px) {
        padding-bottom: 25px;

        a {
            font-size: 16px;
        }
    }   
    
    @media (max-width: 700px) {
        a {
            font-size: 14px;
        }
    }    
    
    @media (max-width: 600px) {
        flex-wrap: wrap;
        height: 75px;

        a {
            width: 49%;
            text-align: center;
            margin-top: 15px;
        }
    }
`;

export const ForaImgAmbientes = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

   img {
    width: 67%;
   }

   .ForaTextAmbientes {
        width: 31%;

        .TextAmbientes1 {
            font-weight: bold;
            font-size: 25px;
            color: #fc551e;
        }        
        
        .TextAmbientes2 {
            font-weight: 500;
            font-size: 17px;
            padding-top: 15px;
            line-height: 25px;
        }
   }

   @media (max-width: 1050px) {
        img {
            width: 65%;
        }

        .ForaTextAmbientes {
            width: 30%;
        }

        .ForaTextAmbientes {
            .TextAmbientes2 {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    @media (max-width: 850px) {
        img {
            width: 100%;
        }

        .ForaTextAmbientes {
            width: 100%;
            padding-top: 20px;        
        }
    }
`;