import styled from 'styled-components';
import ImgBgRestaurante from "../../Images/BgRestaurante.jpg";
import ImgBgRestauranteResp from "../../Images/BgRestauranteResp.jpg";

export const BgTextRestaurante = styled.section`
    padding-top: 40px;

    .ForaTextRestaurante {
        width: 1238px;
        margin: 0 auto;
        text-align: center;
    }

    h1 {
        text-transform: uppercase;
        font-size: 60px;
        font-weight: 900;
        width: 751px;
        margin: 0 auto;
    }

    p {
        color: #b9b9b9;
        font-size: 20px;
        line-height: 30px;
        padding-top: 20px;
        padding-bottom: 25px;
    }

    @media (max-width: 1350px) {
        .ForaTextRestaurante {
            width: 85%;
        }

        h1 {
            font-size: 50px;
            width: 706px;
        }

        p {
            font-size: 18px;
            line-height: 26px;
            padding-top: 10px;
        }
    }

    @media (max-width: 785px) {
        h1 {
            font-size: 40px;
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        h1 {
            font-size: 30px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

    }
`;

export const BgRestaurante = styled.section`
    background-image: url(${ImgBgRestaurante});
    background-position: right;
    background-size: cover;

    img {
        display: none;
    }

    .ForaRestaurante {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        padding-top: 7.5vw;
        padding-bottom: 7.5vw;
    }

    .RightRestaurante {
        width: 540px;
    }

    h2 {
        text-transform: uppercase;
        font-size: 40px;
        font-weight: 900;
    }

    p {
        color: #fff;
        font-size: 20px;
        line-height: 28px;
    }

    @media (max-width: 610px) { 
        background-image: none;
        background-color: #491a00;

        .ForaRestaurante {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        h2 {
            font-size: 34px;
        }
    }
`;

export const BgProdutos = styled.section`
    .ForaProdutos {
        width: 1290px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 25px;
    }

    h2 {
        text-transform: uppercase;
        font-size: 40px;
        font-weight: 900;
    }

    p {
        color: #b9b9b9;
        font-size: 20px;
        line-height: 25px;
        padding-top: 0px;
        padding-bottom: 5px;
    }

    .EsqProdutos {
        width: 48%;
    }

    .DirProdutos {
        width: 49%;
    }

    .ButtonCardapio {
        background-color: #fc551e;
        border: 0px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        width: 185px;
        height: 31px;
        border-radius: 10px;
    }

    .slick-prev {
        left: -30px !important;
        z-index: 100;
        color: #fff !important;
        outline: none !important;
        background: transparent !important;
    }

    .slick-next {
        right: -30px !important;
    }

    .slick-prev:before,
    .slick-next:before
    {
        color: #fff !important;
        margin-top: 1px;
    }

    .slick-dots {
        bottom: -38px !important;
    }

    .TextProdutos {
        font-weight: bold;
        text-align: center;
        padding-top: 20px;
    }

    @media (max-width: 1380px) {
        .ForaProdutos {
            width: 900px;
        }
    }

    @media (max-width: 990px) {
        .ForaProdutos {
            width: 85%;
            flex-direction: column;
        }

        .EsqProdutos {
            width: 100%;
        }

        .DirProdutos {
            max-width: 590px;
            width: 100%;
            margin-top: 40px;
        }
    }

    @media (max-width: 500px) {
        .ForaProdutos {
            padding-top: 25px;
        }

        h2 {
            font-size: 35px;
        }

        .DirProdutos {
            width: 90%;
        }
    }
`;

