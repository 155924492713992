import styled from 'styled-components';

import ImgBgFacaEventos from "../../Images/BgFacaEventos.jpg";

export const BgEventos = styled.section`
    // background-image: url(${ImgBgFacaEventos});
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-top: 2vw;

    @media (max-width: 650px) {
        margin-bottom: 30px;
    }   

`;

export const ForaEventos = styled.div`
    width: 90%;
    max-width: 1280px;
    margin: 30px auto 0 auto;

    .ImgEventosResp {
        display: none;
    }

    img {
        width: 100%;
        height: auto;
    }

    @media (max-width: 800px) {

    .ImgEventos {
        display: none;
    }    
    
    .ImgEventosResp {
        display: block;
    }

    }

`;