/* eslint-disable import/first */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const endpoint = process.env.REACT_APP_ENDPOINT;

import ImgRedes1 from "../../Images/ImgRedes1.png";
import ImgRedes2 from "../../Images/ImgRedes2.png";
import ImgRedes3 from "../../Images/ImgRedes3.png";
import ImgRedes4 from "../../Images/ImgRedes4.png";
import ImgRedes5 from "../../Images/ImgRedes5.png";
import ImgIconShows from "../../Images/IconShows.png";
import ImgApoio from "../../Images/ImgApoio.jpg";
import ImgLogo from "../../Images/ImgLogo.png";

function Redes(props: any) {
  const [dados, setDados] = useState([]);
  const [load, setLoad] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}/apoio.php`);
        setLoad(true);
        if (!response.ok) {
          throw new Error("Não foi possível obter os dados da API");
        }
        const data = await response.json();
        setDados(data);
        setLoad(false);
      } catch (error) {
        console.error("Erro ao buscar os dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {load == true && <Load status={true} />}

      <S.BgRedes>
        <S.ForaRedes>
          <h2>Villa Nas Redes</h2>
          <S.ForaImgRedes>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/villacountry/"
            >
              <img alt="Ícone Instagram" src={ImgRedes1} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/villacountry"
            >
              <img alt="Ícone Facebook" src={ImgRedes2} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@villacountryoficial"
            >
              <img alt="Ícone TikTok" src={ImgRedes3} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://open.spotify.com/user/villacountry?si=0a8fce6065684d35&nd=1&dlsi=1723aafb2ff043a8"
            >
              <img alt="Ícone Spotify" src={ImgRedes4} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.radiovillacountry.com.br/"
            >
              <img alt="Ícone Rádio Villa Country" src={ImgRedes5} />
            </a>
          </S.ForaImgRedes>

          <S.TopShows>
            <div className="RiskShows"></div>
            <img alt="Icon" src={ImgIconShows} />
            <div className="TextShows">APOIO</div>
            <img alt="Icon" src={ImgIconShows} />
            <div className="RiskShows"></div>
          </S.TopShows>

          <Slider className="SliderBanner2" {...settings}>
            {dados.map((item: any, index) => (
              <a
                key={index}
                target="_blank"
                style={{ width: "100%" }}
                rel="noreferrer"
                href={item.c_link}
              >
                <img
                  alt="Banner de apoio Villa Country"
                  src={`https://appvillacountry.com.br/uploadnew/apoio/${item.id_tb_apoio}.${item.c_extensao}`}
                />
              </a>
            ))}
          </Slider>
        </S.ForaRedes>
        <S.BgRedesBaixo>
          <img src={ImgLogo} />
        </S.BgRedesBaixo>
      </S.BgRedes>
    </>
  );
}

export default withRouter(Redes);
