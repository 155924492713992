import styled from 'styled-components';
import ImgBgSite from "../../Images/BgSite.jpg";

export const BgEventos = styled.section`
    background-image: url(${ImgBgSite});
    background-repeat: repeat;

    .ImgBilheteria {
        width: 100%;
        max-width: 1280px;
        height: auto;
        margin-top: 15px;
    }

    .ImgBilheteriaResp {
        width: 100%;
        max-width: 1280px;
        height: auto;
        margin-top: 15px;
        display: none;
    }

    @media (max-width: 800px) {
        .ImgBilheteria {
            display: none;
        }

    .ImgBilheteriaResp {
        display: block;
    }
    }   
`;

export const ForaBilheteria = styled.div`
    width: 90%;
    margin: 0 auto;
    text-align: center;

    .TextBilheteria {
        font-weight: bold;
        font-size: 26px;
        padding-top: 25px;
    }

    p {
        line-height: 16px;
        color: #fc551e;
    }

    @media (max-width: 650px) {
        margin-bottom: 30px;

        .TextBilheteria {
            padding-top: 0px;
        }
    }
`;

export const ForaEventos = styled.div`
    width: 90%;
    margin: 0 auto;
`;

export const TopShows = styled.div`
    font-family: 'Poppins', sans-serif;
    color: #fc551e;
    width: 550px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 40px;

    .RiskShows {
        width: 60px;
        height: 2px;
        background-color: #fc551e;
    }

    .TextShows {
        font-weight: bold;
        font-size: 25px;
    }

    @media (max-width: 650px) {
        width: 95%;
        padding-top: 0px;
        padding-bottom: 15px;

        .TextShows {
            font-size: 18px;
        }

        .RiskShows {
            width: 30px;
        }
    }
`;

export const ContentEventos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TopContent = styled.div`
    display: flex;
    align-items: center;

    .TextTopContent1 {
        font-size: 25px;
        font-weight: 900;
        text-transform: uppercase;

        span {
            font-weight: 300;
        }
    }

    a {
        font-weight: bold;
        font-size: 18px;
        background-color: #fc551e;
        width: 240px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        text-transform: uppercase;
        margin-left: 20px;
        color: #fff;
        text-decoration: none;
    }

    .ItemRotas {
        display: flex;
        align-items: center;
        margin-top: 15px;

        img {
            width: 50px;
            margin-left: 20px;
        }
    }

    @media (max-width: 1310px) {
        .TextTopContent1 {
            font-size: 20px;
        }

        a {
            font-size: 17px;
            width: 175px;
            height: 30px
        }
    }    
    
    @media (max-width: 1000px) {
        flex-direction: column;

        a {
            margin-top: 20px;
        }
    }

    @media (max-width: 1310px) {
        .TextTopContent1 {
            font-size: 17px;
        }
        
    }    
`;

export const MidContent = styled.div`
    width: 100%;
    display: flex;
    margin-top: 55px;

    .ImgEsta {
        width: 50%;
    }

    .RightMidContent {
        width: 50%;
    }

    @media (max-width: 1550px) {
        flex-wrap: wrap;
        width: 700px;

        .ImgEsta {
            width: 100%;
        }

        .RightMidContent {
            width: 100%;
        }
    }    
    
    @media (max-width: 780px) {
        width: 100%;
    }  
    
    @media (max-width: 650px) {
        margin-top: 30px;
    }

    @media (max-width: 500px) {
        .ItemContent2 {
            height: 255px !important;
       } 
    }
`;

export const ItemMidContent = styled.div`
    height: 50%;
    display: flex;
    align-items: center;

    img {
        width: 110px;
    }

    .ForaItemMidContent {
        width: 90%;
        margin: 0 auto;

        h2 {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: 900;
        }
    }

    @media (max-width: 1550px) {
        height: 250px;
    }        
    
    @media (max-width: 750px) {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }    
`;

export const ForaEntradas = styled.div`
    display: flex;
    justify-content: space-between;

    .LeftEntradas {
        width: 78%;
        margin-top: 40px;
    }

    .ItemEntradas {
        display: flex;

        .TextEntrada1 {
            font-weight: 900;
            font-size: 20px;
            text-transform: uppercase;
            padding-left: 10px;
            padding-right: 10px;
            width: 125px;
            margin-top: 4px;
        }        
        
        .TextEntrada2 {
            font-weight: 300;
            font-size: 20px;
            margin-top: 4px;
        }

        .ForaNumeroItem {
            display: flex;
            flex-direction: column;
            align-items: center;

            .RiskEntrada {
                height: 17px;
                width: 3px;
                background-color: #fff;
                margin-bottom: -1px;
            }
        }

        .RiskEntrada2 {
            width: 20px;
            background-color: #fff;
            height: 3px;
            margin-left: 13px;
            margin-top: -1px;
        }

        .TextEntrada3 {
            margin-top: -15px;
            margin-left: 30px;
        }
    } 
    
    .LeftEntradasBaixo {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 0px;
    }

    .ItemEntradasBaixo {
        margin-top: 15px;
        width: 48%;
    }

    .ForaTextBaixo {
        padding-left: 15px;
        width: 85%;
    }    
    
    .ForaTextBaixo .TextEntrada1 {
        padding-left: 0px;
    }

    @media (max-width: 1630px) {
        .LeftEntradas {
            margin-top: 15px;
        }

        .ItemEntradasBaixo {
            margin-top: 5px;
        }
    }         
    
    @media (max-width: 750px) {
        .LeftEntradas {
            width: 100%;
            flex-wrap: wrap;
            margin-top: 5px;
        }

        .ImgLocal {
            display: none;
        }

        .ItemEntradas {
            width: 100%;

            .TextEntrada1 {
                font-size: 17px;
            }        
            
            .TextEntrada2 {
                font-size: 17px;
            }
        }
    }       
    
    @media (max-width: 673px) {
        .ForaTextBaixo {
            padding-left: 10px;
            width: 80%;
        }
    }         
    
    @media (max-width: 500px) {
        .ItemEntradasBaixo {
            width: 100%;
        }

        .LeftEntradas {
            margin-top: 0px;
        }

        .ItemEntradas {
            .TextEntrada1 {
                width: 122px;
            }            
            
            .TextEntrada2 {
                margin-top: 0px;
            }
        }
    }     
`;

export const NumeroItem = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 69px;
    display: flex;
    align-items: center;
    justify-content: center;

    .NumeroEntrada {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: bold;
    }
`;

export const BottomContent = styled.div`
    width: 1040px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 45px;

    .RiskContent {
        width: 2px;
        height: 310px;
        background-color: #343434;
    }

    @media (max-width: 1400px) {
        width: 775px;
    }      
    
    @media (max-width: 850px) {
        width: 100%;
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;

        .RiskContent {
            width: 100%;
            height: 2px;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }      
    
    @media (max-width: 650px) {
        padding-top: 15px;
    }      
    
    @media (max-width: 500px) {
        .RiskContent {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }   
`;

export const ItemBottomContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    img {
        width: 120px;
    }

    h2 {
        font-weight: 900;
        font-size: 35px;
    }

    h3 {
        font-weight: bold;
        font-size: 30px;
    }

    h4 {
        font-weight: 300;
        font-size: 25px;
    }

    a {
        font-size: 18px;
        font-weight: bold;
        width: 200px;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        text-transform: uppercase;
        margin-top: 12px;
        cursor: pointer;
        text-decoration: none;
        color: #fff;
    }

    @media (max-width: 1400px) {
        img {
            width: 100px;
        }

        h2 {
            font-weight: 900;
            font-size: 30px;
        }

        h3 {
            font-weight: bold;
            font-size: 25px;
        }

        h4 {
            font-weight: 300;
            font-size: 20px;
        }

    }      
    
    @media (max-width: 850px) {
        width: 100%;

        img {
            width: 70px;
        }

        h2 {
            font-size: 25px;
        }

        h3 {
            font-size: 24px;
        }

        h4 {
            font-size: 21px;
            text-align: center;
        }

        a {
            font-size: 16px;
        }
    }   
`;