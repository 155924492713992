import styled from 'styled-components';
import ImgBgSite from "../../Images/BgSite.jpg";

export const ForaNoVila = styled.section`
    background-image: url(${ImgBgSite});
    background-repeat: repeat;
`;

export const TopShows = styled.div`
    font-family: 'Poppins', sans-serif;
    color: #fc551e;
    width: 550px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 75px;
    padding-bottom: 40px;

    .RiskShows {
        width: 60px;
        height: 2px;
        background-color: #fc551e;
    }

    .TextShows {
        font-weight: bold;
        font-size: 25px;
    }

    @media (max-width: 650px) {
        width: 95%;
        padding-top: 0px;
        padding-bottom: 15px;

        .TextShows {
            font-size: 18px;
        }

        .RiskShows {
            width: 30px;
        }
    }
`;

export const ContentNoVila = styled.div`
    width: 90%;
    margin: 0 auto;
    padding-bottom: 70px;

    .ItemNoVila img {
        width: 96% !important;
        filter: grayscale(100%);
    }   
    
    .ItemNoVila img:hover {
        cursor: pointer;
        filter: grayscale(0%);
    }
`;
