/* eslint-disable import/first */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const endpoint = process.env.REACT_APP_ENDPOINT;

import ImgLogo from "../../Images/Logo.png";
import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";
import IconRedes4 from "../../Images/IconRedes4.png";
import IconRedes5 from "../../Images/IconRedes5.png";

import ImgBanner from "../../Images/Banner.jpg";

function Header(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}/banners.php`);
        setLoad(true);
        if (!response.ok) {
          throw new Error("Não foi possível obter os dados da API");
        }
        const data = await response.json();
        setDados(data);

        setLoad(false);
      } catch (error) {
        console.error("Erro ao buscar os dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {load == true && <Load status={true} />}

      <S.BgHeader>
        <section>
          <img className="ImgLogo" alt="Logo" src={ImgLogo} />
          <S.ForaMenu>
            <a href="/#Home">HOME</a>
            <a href="/#Programacao">PROGRAMAÇÃO</a>
            <a href="/#Aniversarios">ANIVERSÁRIOS</a>
            {/*
            <a href="/#Premium">VILLA PREMIUM</a>
            <a href="/#App">APP</a>
            */}
            <a href="/Restaurante">RESTAURANTE</a>
            <a href="/#Ambientes">AMBIENTES</a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.gruposaopauloeventos.com.br/villacountry/"
            >
              EVENTOS
            </a>
            <a href="/#Local">LOCALIZAÇÃO</a>
            <a href="/#Contato">CONTATO</a>
          </S.ForaMenu>
          <S.ForaRedes>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/villacountry/"
            >
              <img alt="Ícone Instagram" src={IconRedes1} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/villacountry"
            >
              <img alt="Ícone Facebook" src={IconRedes2} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@villacountryoficial"
            >
              <img alt="Ícone Tik Tok" src={IconRedes3} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://open.spotify.com/user/villacountry?si=0a8fce6065684d35&nd=1&dlsi=1723aafb2ff043a8"
            >
              <img alt="Ícone Spotify" src={IconRedes4} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.radiovillacountry.com.br/"
            >
              <img alt="Ícone Spotify" src={IconRedes5} />
            </a>
          </S.ForaRedes>
          <S.ForaBotao onClick={() => setMenu(1)}>
            <div />
            <div />
            <div />
          </S.ForaBotao>
        </section>
      </S.BgHeader>

      {menu == 1 && (
        <S.BgMenuMobile>
          <div onClick={() => setMenu(0)} className="XMobile">
            X
          </div>
          <div className="ForaMenuMobile">
            <h2>Villa Country</h2>
            <S.ItensMenuMobile>
              <a onClick={() => setMenu(0)} href="/#Home">
                HOME
              </a>
              <a onClick={() => setMenu(0)} href="/#Programacao">
                PROGRAMAÇÃO
              </a>
              <a onClick={() => setMenu(0)} href="/#Aniversarios">
                ANIVERSÁRIOS
              </a>
              {/*
              <a onClick={() => setMenu(0)} href="/#Premium">
                VILLA PREMIUM
              </a>
              
              <a onClick={() => setMenu(0)} href="/#App">
                APP
              </a>
              */}
              <a onClick={() => setMenu(0)} href="/Restaurante">
                RESTAURANTE
              </a>
              <a onClick={() => setMenu(0)} href="/#Ambientes">
                AMBIENTES
              </a>
              <a
                onClick={() => setMenu(0)}
                target="_blank"
                rel="noreferrer"
                href="https://www.gruposaopauloeventos.com.br/villacountry/"
              >
                EVENTOS
              </a>
              <a onClick={() => setMenu(0)} href="/#Local">
                LOCALIZAÇÃO
              </a>
              <a onClick={() => setMenu(0)} href="/#Contato">
                CONTATO
              </a>
            </S.ItensMenuMobile>
            <S.ForaRedesMenuMobile>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/villacountry/"
              >
                <img src={IconRedes1} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/villacountry"
              >
                <img src={IconRedes2} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tiktok.com/@villacountryoficial"
              >
                <img src={IconRedes3} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/user/villacountry?si=0a8fce6065684d35&nd=1&dlsi=1723aafb2ff043a8"
              >
                <img src={IconRedes4} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.radiovillacountry.com.br/"
              >
                <img alt="Ícone Spotify" src={IconRedes5} />
              </a>
            </S.ForaRedesMenuMobile>
          </div>
        </S.BgMenuMobile>
      )}
    </>
  );
}

export default withRouter(Header);
