/* eslint-disable react/prop-types */
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import IconProgramacao from "../../Images/IconProgramacao.png";
import IconProgramacao2 from "../../Images/IconProgramacao2.png";

function Programacao(props: any) {
  return (
    // <>
    //   <div id='Reservas' style={{marginTop: "-100px", position: "absolute"}}></div>
    //   <S.BgProgramacao>
    //     <S.ForaProgramacao>
    //       <img alt='Ícone Agenda' src={IconProgramacao} />
    //       <div className='TextProgramacao1'>RESERVAS NÃO SÃO VÁLIDAS PARA DIA COM SHOW!</div>
    //       <div className='TextProgramacao2'>
    //         Atualmente não se faz necessário realizar reserva para o restaurante ou demais espaços.
    //       </div>
    //       <div className='TextProgramacao2'>
    //         Caso opte por realizar uma reserva entre em contato apenas por WhatsApp no telefone 11 98724-0123
    //         de terça a domingo das 11 às 19hrs (atendimento apenas via mensagem WhatsApp não atendemos ligação)
    //       </div>

    //       {/*
    //       <S.ContentProgramacao>
    //         <div className='ItemProgramacao'>
    //           <div className='ContentItemProgramacao'>
    //             <div className='TextItemProgramacao1'>QUI</div>
    //             <div className='TextItemProgramacao2'>Quinta <br /> na Pracinha</div>
    //           </div>
    //           <a className='ButtonItemProgramacao'>Ingressos</a>
    //         </div>

    //         <div className='ItemProgramacao'>
    //           <div className='ContentItemProgramacao'>
    //             <div className='TextItemProgramacao1'>SEX</div>
    //             <div className='TextItemProgramacao2'>Sexta <br /> no villa</div>
    //           </div>
    //           <a className='ButtonItemProgramacao'>Ingressos</a>
    //         </div>

    //         <div className='ItemProgramacao'>
    //           <div className='ContentItemProgramacao'>
    //             <div className='TextItemProgramacao1'>SÁB</div>
    //             <div className='TextItemProgramacao2'>sábado <br /> no villa</div>
    //           </div>
    //           <a className='ButtonItemProgramacao'>Ingressos</a>
    //         </div>

    //         <div className='ItemProgramacao'>
    //           <div className='ContentItemProgramacao'>
    //             <div className='TextItemProgramacao1'>DOM</div>
    //             <div className='TextItemProgramacao2'>Violada <br /> na praça</div>
    //           </div>
    //           <a className='ButtonItemProgramacao'>Ingressos</a>
    //         </div>
    //       </S.ContentProgramacao>

    //       <S.BaixoProgramacao>
    //         <img src={IconProgramacao2} />
    //         <a>Faça já a sua Reserva!</a>
    //       </S.BaixoProgramacao>
    //       */}
    //     </S.ForaProgramacao>
    //   </S.BgProgramacao>
    // </>
    null
  );
}

export default withRouter(Programacao);
