import React from "react";
import {
  BrowserRouter,
  /* HashRouter, Router,*/ Route,
  Switch,
} from "react-router-dom";
import Home from "../Pages/Home";
import Restaurante from "../Pages/Restaurante";
import { ThemeProvider } from "styled-components";
import theme from "../Styles/theme";
import Aplicativo from "../Pages/Aplicativo";
import Bilheteria from "../Pages/Bilheteria";
// import Aplicativo from "../Pages/Aplicativo";

const Router = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/Restaurante" exact component={Restaurante} />
            <Route path="/aplicativo" exact component={Aplicativo} />
            <Route path="/Bilheteria" exact component={Bilheteria} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default Router;
