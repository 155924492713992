import React, { useEffect, useState } from "react";
import * as S from "./estilos";
import useData from "../../Utils/useData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Logo from "../../Images/Logo.png";
import ImgFooter1 from "../../Images/ImgFooter1.png";
import ImgFooter3 from "../../Images/ImgFooter3.png";
import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";
import IconRedes4 from "../../Images/IconRedes4.png";
import IconRedes5 from "../../Images/IconRedes5.png";

function Footer() {
  return (
    <>
      <S.BgFooter>
        <img className="LogoFooter" src={Logo} />
        <S.ForaItensMenuFooter>
          <S.ForaMenuFooter>
            <a href="/#Home">HOME</a>
            <a href="#Programacao">PROGRAMAÇÃO</a>
            <a href="#Aniversarios">ANIVERSÁRIOS</a>
            {/*
            <a href="#Premium">VILLA PREMIUM</a>
             */}
          </S.ForaMenuFooter>
          <S.ForaMenuFooter>
            {/*
            <a href="#App">APP</a>
            */}
            <a href="/Restaurante">RESTAURANTE</a>
            <a href="#Ambientes">AMBIENTES</a>
          </S.ForaMenuFooter>
          <S.ForaMenuFooter>
            <a target="_blank" rel="noreferrer" href="https://www.gruposaopauloeventos.com.br/villacountry/">
              EVENTOS
            </a>
            <a href="#Local">LOCALIZAÇÃO</a>
            <a href="#Contato">CONTATO</a>
          </S.ForaMenuFooter>
        </S.ForaItensMenuFooter>
        {/*
        <img alt="Mão Segurando Celular com o App Villa Country" className="ImgCelular" src={ImgFooter1} />
        
        <img className='ImgBaixeApp' src={ImgFooter2} />
        
        <a href="#App">
          <img alt="Ícone Villa premium" className="ImgPremium" src={ImgFooter3} />
        </a>
        */}
        <S.ForaIconsFooter>
          <S.IconRedes>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/villacountry/">
              <img src={IconRedes1} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/villacountry">
              <img src={IconRedes2} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@villacountryoficial">
              <img src={IconRedes3} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://open.spotify.com/user/villacountry?si=0a8fce6065684d35&nd=1&dlsi=1723aafb2ff043a8">
              <img src={IconRedes4} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.radiovillacountry.com.br/">
              <img alt="Ícone Spotify" src={IconRedes5} />
            </a>
          </S.IconRedes>
          <h4>VILLA COUNTRY MACLEMON LTDA. CNPJ: 04.706.822/0001-43 EMPRESA VERIFICADA - PROCONSP</h4>
        </S.ForaIconsFooter>
      </S.BgFooter>
    </>
  );
}

export default Footer;
