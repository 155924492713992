import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgIconShows from "../../Images/IconShows.png";
import ImgLocal from "../../Images/ImgLocal.jpg";
import ImgIconLocal1 from "../../Images/IconLocal1.png";
import ImgIconLocal2 from "../../Images/IconLocal2.png";
import ImgIconLocal3 from "../../Images/IconLocal3.png";

import LogoMaps from "../../Images/Logo_google_maps.png";
import LogoWaze from "../../Images/Logo_waze.png";

import ImgBilheteria from "../../Images/ImgBilheteria.jpg";
import ImgBilheteriaResp from "../../Images/ImgBilheteriaResp.jpg";

interface LocalizacaoProps extends RouteComponentProps {
  visibilidade?: boolean; // Torna a prop opcional
}

const Localizacao: React.FC<LocalizacaoProps> = ({ visibilidade = true }) => { 
  return (
    <>
      <S.BgEventos>
        {/*
        {visibilidade == true && (
          <S.ForaBilheteria>
            <div className="TextBilheteria">BILHETERIA</div>
            <p>Adquira seu ingresso através da Ticket 360 ou diretamente na bilheteria do Villa Country.</p>
            <p>Quinta a domingo das 20h às 3h</p>
            <p>
              Presencialmente você adquiri o seu ingresso SEM TAXA e parcelar em até 3x sem juros e 10x com juros!
            </p>
            <p>
              Você também pode adquirir o seu ingresso no Espaço Unimed com os mesmos benefícios de segunda a sábado
              das 10h às 19h.
            </p>
            <p>Rua Tagipuru, 795 - Barra Funda</p>
            <p>Evite filas, não deixe para a última hora.</p>
          </S.ForaBilheteria>
        )}
        */}
        <S.ForaBilheteria>
        <a href='/Bilheteria' target='_blank'>
          <img src={ImgBilheteria} className="ImgBilheteria" />
        </a>
        
        <a href='/Bilheteria' target='_blank'>
          <img src={ImgBilheteriaResp} className="ImgBilheteriaResp" />
        </a>
        </S.ForaBilheteria>

        <div id="Local" style={{ marginTop: "-100px", position: "absolute" }}></div>

        <S.ForaEventos style={{paddingTop: '20px'}}>
          <S.TopShows>
            <div className="RiskShows"></div>
            <img alt="Icon" src={ImgIconShows} />
            <div className="TextShows">Localização</div>
            <img alt="Icon" src={ImgIconShows} />
            <div className="RiskShows"></div>
          </S.TopShows>

          <S.ContentEventos>
            <S.TopContent>
              <div className="TextTopContent1">
                Av. Francisco Matarazzo, 774 - <span>Parque da Água Branca - São Paulo / SP</span>
              </div>
              <div className="ForaRotas">
                <div className="ItemRotas">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.google.com/maps/place/Villa+Country/@-23.5279269,-46.6734088,17z/data=!4m19!1m12!4m11!1m3!2m2!1d-46.6704454!2d-23.5278477!1m6!1m2!1s0x94ce58020eab0a59:0x105427a1d544d263!2sAv.+Francisco+Matarazzo,+774+-+%C3%81gua+Branca,+S%C3%A3o+Paulo+-+SP,+05001-000!2m2!1d-46.6698907!2d-23.5277663!3m5!1s0x94ce5802fdab9fd7:0x8f19e039ceb9e889!8m2!3d-23.5277938!4d-46.6704864!16s%2Fg%2F11f_29p2wz?entry=ttu"
                  >
                    Traçar rota
                  </a>
                  <img src={LogoMaps} />
                </div>
                <div className="ItemRotas">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.waze.com/pt-PT/live-map/directions/sao-paulo/sao-paulo?to=place.ws.row.7619654.774"
                  >
                    Traçar rota
                  </a>
                  <img src={LogoWaze} />
                </div>
              </div>
            </S.TopContent>
            <S.MidContent>
              <img alt="Mapa Local Villa Country" src={ImgLocal} className="ImgEsta" />
              <div className="RightMidContent">
                <S.ItemMidContent style={{ backgroundColor: "#078443" }}>
                  <div className="ForaItemMidContent">
                    <h2>Estacionamento</h2>
                    <S.ForaEntradas>
                      <div className="LeftEntradas">
                        <div className="ItemEntradas">
                          <div className="ForaNumeroItem">
                            <S.NumeroItem style={{ backgroundColor: "#fff", color: "#078443" }}>
                              <div className="NumeroEntrada">1</div>
                            </S.NumeroItem>
                            <div className="RiskEntrada" />
                          </div>
                          <div className="TextEntrada1">Entrada 1</div>
                          <div className="TextEntrada2">Av. Francisco Matarazzo, 892</div>
                        </div>
                        <div className="ItemEntradas">
                          <div className="ForaNumeroItem">
                            <S.NumeroItem style={{ backgroundColor: "#fff", color: "#078443" }}>
                              <div className="NumeroEntrada">2</div>
                            </S.NumeroItem>
                            <div className="RiskEntrada" />
                            <div className="RiskEntrada" />
                          </div>
                          <div className="TextEntrada1">Entrada 2</div>
                          <div className="TextEntrada2">R. Dona Germaine Burchard, 77</div>
                        </div>
                        <div className="ItemEntradas">
                          <div className="RiskEntrada2"></div>
                          <div className="TextEntrada3">470 Vagas Cobertas | Seguro | Manobrista</div>
                        </div>
                      </div>
                      <img alt="Ícone Estacionamento" className="ImgLocal" src={ImgIconLocal1} />
                    </S.ForaEntradas>
                  </div>
                </S.ItemMidContent>
                <S.ItemMidContent className="ItemContent2" style={{ backgroundColor: "#fc551e" }}>
                  <div className="ForaItemMidContent">
                    <h2>Outras opções:</h2>
                    <S.ForaEntradas>
                      <div className="LeftEntradas LeftEntradasBaixo">
                        <div className="ItemEntradas ItemEntradasBaixo">
                          <S.NumeroItem style={{ backgroundColor: "#fff", color: "#078443" }}>
                            <div className="NumeroEntrada">A</div>
                            <div className="RiskEntrada" />
                          </S.NumeroItem>
                          <div className="ForaTextBaixo">
                            <div className="TextEntrada1">PARX</div>
                            <div className="TextEntrada2">Rua Julio Gonzales, 132</div>
                          </div>
                        </div>
                        <div className="ItemEntradas ItemEntradasBaixo">
                          <S.NumeroItem style={{ backgroundColor: "#fff", color: "#078443" }}>
                            <div className="NumeroEntrada">B</div>
                            <div className="RiskEntrada" />
                          </S.NumeroItem>
                          <div className="ForaTextBaixo">
                            <div className="TextEntrada1">RIVER PARK</div>
                            <div className="TextEntrada2">R. Tagipuru, 931 | Av. Francisco Matarazzo, 678</div>
                          </div>
                        </div>
                        <div className="ItemEntradas ItemEntradasBaixo">
                          <S.NumeroItem style={{ backgroundColor: "#fff", color: "#078443" }}>
                            <div className="NumeroEntrada">C</div>
                            <div className="RiskEntrada" />
                          </S.NumeroItem>
                          <div className="ForaTextBaixo">
                            <div className="TextEntrada1">GLOBO PARK</div>
                            <div className="TextEntrada2">R. Tagipuru, 835 | Av. Francisco Matarazzo, 664</div>
                          </div>
                        </div>
                        <div className="ItemEntradas ItemEntradasBaixo">
                          <S.NumeroItem style={{ backgroundColor: "#fff", color: "#078443" }}>
                            <div className="NumeroEntrada">D</div>
                            <div className="RiskEntrada" />
                          </S.NumeroItem>
                          <div className="ForaTextBaixo">
                            <div className="TextEntrada1">BRASIL PARK</div>
                            <div className="TextEntrada2">R. Tagipuru, 733</div>
                          </div>
                        </div>
                      </div>
                    </S.ForaEntradas>
                  </div>
                </S.ItemMidContent>
              </div>
            </S.MidContent>

            <S.BottomContent>
              <S.ItemBottomContent>
                <img alt="Ícone Agenda com relógio" src={ImgIconLocal2} />
                <h2>Funcionamento</h2>
                <h3 style={{ color: "#fc551e" }}>DE QUINTA À DOMINGO</h3>
                <h4 style={{ color: "#ed890e" }}>A PARTIR DAS 20H</h4>
                <a href="#Programacao" style={{ backgroundColor: "#fc551e" }}>
                  Programação
                </a>
              </S.ItemBottomContent>
              <div className="RiskContent"></div>
              <S.ItemBottomContent>
                <img alt="Ícone Whats App" src={ImgIconLocal3} />
                <h2>Atendimento</h2>
                <h3 style={{ color: "#15a630" }}>11 98724-0123</h3>
                <h4 style={{ color: "#15a630" }}>SEGUNDA À SÁBADO - 11H às 19H</h4>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/5511987240123"
                  style={{ backgroundColor: "#15a630" }}
                >
                  Entre em contato
                </a>
              </S.ItemBottomContent>
            </S.BottomContent>
          </S.ContentEventos>
        </S.ForaEventos>
      </S.BgEventos>
    </>
  );
};

export default withRouter(Localizacao);
