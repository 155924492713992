import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgBannerRestaurante1 from "../../Images/ImgBannerRestaurante1.jpg";
import ImgBannerRestaurante2 from "../../Images/ImgBannerRestaurante2.jpg";
import ImgBannerRestaurante3 from "../../Images/ImgBannerRestaurante3.jpg";
import ImgBannerRestaurante4 from "../../Images/ImgBannerRestaurante4.jpg";
import ImgBannerRestauranteResp1 from "../../Images/ImgBannerRestauranteResp1.jpg";
import ImgBannerRestauranteResp2 from "../../Images/ImgBannerRestauranteResp2.jpg";
import ImgBannerRestauranteResp3 from "../../Images/ImgBannerRestauranteResp3.jpg";
import ImgBannerRestauranteResp4 from "../../Images/ImgBannerRestauranteResp4.jpg";

const endpoint = process.env.REACT_APP_ENDPOINT;

interface BannerProps extends RouteComponentProps {
  visibilidade?: boolean; // Torna a prop opcional
}

const BannerRestaurante: React.FC<BannerProps> = ({ visibilidade = true }) => { 
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <>
      <S.ForaBanner id="Home">
        <Slider className="SliderBanner1" {...settings}>
            <img alt="Banner Restaurante Villa Country" src={ImgBannerRestaurante1} />
            <img alt="Banner Restaurante Villa Country" src={ImgBannerRestaurante2} />
            <img alt="Banner Restaurante Villa Country" src={ImgBannerRestaurante3} />
            <img alt="Banner Restaurante Villa Country" src={ImgBannerRestaurante4} />
        </Slider>
      </S.ForaBanner>

      <S.ForaBannerResp id="Home">
        <Slider className="SliderBanner1" {...settings}>
        <img alt="Banner Evento" src={ImgBannerRestauranteResp1} />
        <img alt="Banner Evento" src={ImgBannerRestauranteResp2} />
        <img alt="Banner Evento" src={ImgBannerRestauranteResp3} />
        <img alt="Banner Evento" src={ImgBannerRestauranteResp4} />
        </Slider>
      </S.ForaBannerResp>
    </>
  );
};

export default withRouter(BannerRestaurante);
