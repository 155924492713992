import styled from 'styled-components';
import ImgBgBanner from "../../Images/BgBanner.jpg";

export const ForaBanner = styled.div`
    padding-top: 100px;

    a {
        text-decoration: none !important;
    }

    div {
        background-image: url(${ImgBgBanner});
    }

    .slick-slide img {
        width: 100%;
    }

    @media (max-width: 800px) {
        display: none;
    }   
`;

export const ForaBannerResp = styled.div`
    display: none;

    padding-top: 100px;

    div {
        background-image: url(${ImgBgBanner});
    }

    .slick-slide img {
        width: 100%;
    }

    @media (max-width: 800px) {
        display: flex;

        .slick-dots {
            display: none !important;
        }
    }   
`;

export const ForaInfoBanner = styled.div`
    background-color: #181818;
    padding-top: 30px;
    padding-bottom: 60px;

    @media (max-width: 800px) {
        padding-top: 15px;
        padding-bottom: 25px;
    }   

`;

export const ForaContentBanner = styled.div`
    width: 93%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        font-size: 18px;
        font-weight: bold;
        background-color: #fc551e;
        width: 165px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        border-radius: 7px;
        height: 35px;
        color: #fff;
        cursor: pointer;
    }

    @media (max-width: 1140px) {
        width: 90%;
    }    
    
    @media (max-width: 800px) {
       button {
        margin-top: 5px;
       } 

        flex-direction: column;
    }
`;

export const TextBanner = styled.div`
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    width: 100%;
    color: #fff;
    text-decoration: none !important;
    border: 0px;
    text-align: justify;

    span {
        font-weight: 300;
    }

    @media (max-width: 1310px) {
        font-size: 22px;
    }   
    
    @media (max-width: 870px) {
        font-size: 19px;
        text-align: center;
        margin-bottom: 12px;
        margin-top: 8px;
    }   
    
    @media (max-width: 500px) {
        font-size: 15px;
        text-align: center;
        margin-bottom: 12px;
        margin-top: 8px;
    }
`;
