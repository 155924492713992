import styled from 'styled-components';
// import BgRodape from "../../Images/BgRodape.jpg";

export const BgFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;

  .ImgBaixeApp {
    width: 299px;
    height: 87px;
  }

  .ImgCelular {
    margin-top: -40px;
  }

  @media (max-width: 1325px) {
    width: 95%;
  }  
  
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    
    .ImgPremium {
      display: none;
    }
  }  
  
  @media (max-width: 880px) {
    padding-top: 20px;
    padding-bottom: 20px;
    
    .ImgCelular {
      display: none;
    }
  }  
  
  @media (max-width: 730px) {
    width: 90%;

    .ImgPremium {
      display: block;
      padding-top: 25px;
    }
  }

  @media (max-width: 550px) {
    justify-content: center;

    .LogoFooter {
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
`;

export const ForaItensMenuFooter = styled.div`
    display: flex;
    width: 350px;
    justify-content: space-between;

    @media (max-width: 730px) {
      width: 370px;
    }    
    
    @media (max-width: 550px) {
      width: 100%;
    }
`;

export const ForaMenuFooter = styled.div`
    display: flex;
    flex-direction: column;

    a {
      font-weight: 300;
      font-size: 15px;
      text-transform: uppercase;
      line-height: 24px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
    }

    @media (max-width: 550px) {
      a{
        font-size: 14px;
      }
    }
`;

export const ForaIconsFooter = styled.div`
    width: 311px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

  h4 {
    color: #818181;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
  }

  @media (max-width: 1325px) {
    width: 220px;
  }  
  
  @media (max-width: 730px) {
    padding-top: 25px;
  }  
  
  @media (max-width: 730px) {
    width: 100%;
  }
`;

export const IconRedes = styled.div`
    width: 155px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    img {
      width: 28px;
    }
`;