/* eslint-disable react/prop-types */
import { useState } from "react";
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgProdutos from "../../Images/ImgProdutos.jpg";
import ImgProdutos2 from "../../Images/ImgProdutos2.jpg";
import ImgProdutos3 from "../../Images/ImgProdutos3.jpg";
import ImgProdutos4 from "../../Images/ImgProdutos4.jpg";
import ImgProdutos5 from "../../Images/ImgProdutos5.jpg";
import ImgProdutos6 from "../../Images/ImgProdutos6.jpg";
import ImgProdutos8 from "../../Images/ImgProdutos8.jpg";
import ImgProdutos9 from "../../Images/ImgProdutos9.jpg";
import ImgRestaurante from "../../Images/ImgRestaurante.jpg";

function RestauranteContent(props: any) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <S.BgTextRestaurante>
        <div className="ForaTextRestaurante">
          <h1>Restaurante Exclusivo do Villa Country</h1>
          <p>
            Bem-vindo ao Restaurante Villa Country, um espaço sofisticado e
            independente, situado no coração do complexo Villa Country. Desde
            2002, oferecemos uma experiência gastronômica inigualável,
            combinando excelência culinária com um ambiente elegante e
            acolhedor. Nossa missão é proporcionar momentos inesquecíveis tanto
            para os frequentadores do Villa Country quanto para o público em
            geral.
          </p>
        </div>
      </S.BgTextRestaurante>

      <S.BgRestaurante>
        <img src={ImgRestaurante} />
        <div className="ForaRestaurante">
          <div className="RightRestaurante">
            <h2>Um Espaço Temático</h2>
            <p>
              No Restaurante Villa Country, você encontrará um ambiente que
              captura a essência do Texas em plena São Paulo. Com uma decoração
              inspirada na cultura country e uma trilha sonora que mescla
              clássicos da música sertaneja e country, criamos uma atmosfera
              íntima e envolvente. Cada detalhe, desde a iluminação até a
              mobília, foi cuidadosamente selecionado para garantir uma
              experiência sensorial completa.
            </p>
          </div>
        </div>
      </S.BgRestaurante>

      <S.BgProdutos>
        <div className="ForaProdutos">
          <div className="EsqProdutos">
            <h2>Nossos Produtos</h2>
            <p>
              Nosso cardápio exclusivo é uma fusão de sabores refinados,
              elaborados com ingredientes de altíssima qualidade. Oferecemos uma
              ampla variedade de opções, desde massas e vegetais frescos até
              carnes assadas na brasa, preparadas por nossa talentosa equipe de
              chefs. As carnes, grelhadas no forno a carvão, proporcionam um
              sabor autêntico e suculento. E, para finalizar a sua refeição de
              maneira sublime, nossas sobremesas são o toque final perfeito.
            </p>
            <button
              className="ButtonCardapio"
              onClick={() => {
                window.open(
                  "https://appvillacountry.com.br/uploadnew/cardapio/1.pdf"
                );
              }}
            >
              Nosso Cardárpio
            </button>
          </div>
          <div className="DirProdutos">
            <Slider className="SliderBanner1" {...settings}>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos} />
                <div className="TextProdutos">
                  Batata Frita ao Creme de Queijo
                </div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos2} />
                <div className="TextProdutos">
                  Fettuccine ao Molho Branco com Lascas de Salmão
                </div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos3} />
                <div className="TextProdutos">
                  Salmão Grelhado ao Molho de Alcaparras
                </div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos4} />
                <div className="TextProdutos">Costela Bovina</div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos5} />
                <div className="TextProdutos">Ojo de Bife</div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos6} />
                <div className="TextProdutos">Frango Desossado Grelhado</div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos8} />
                <div className="TextProdutos">Bife de Chorizo</div>
              </div>
              <div className="ItemSliderProdutos">
                <img src={ImgProdutos9} />
                <div className="TextProdutos">Brigadeiro de colher</div>
              </div>
            </Slider>
          </div>
        </div>
      </S.BgProdutos>
    </>
  );
}

export default withRouter(RestauranteContent);
