/* eslint-disable import/first */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

const endpoint = process.env.REACT_APP_ENDPOINT;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IconAniversario from "../../Images/IconAniversario.png";

function Aniversario(props: any) {
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // alert(endpoint);
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}/aniversarios.php`);
        setLoad(true);
        if (!response.ok) {
          throw new Error("Não foi possível obter os dados da API");
        }
        const data = await response.json();
        setDados(data);
        setTimeout(() => {
          setLoad(false);
        }, 2000);
      } catch (error) {
        console.error("Erro ao buscar os dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {load == true && <Load status={true} />}

      <div
        id="Aniversarios"
        style={{ marginTop: "-100px", position: "absolute" }}
      ></div>
      <Slider className="SliderAniversario" {...settings}>
        {dados.map((item: any, index) => (
          <a target="_blank" rel="noreferrer" href={item.c_link} key={index}>
            <img
              alt="Banner Evento"
              src={`https://appvillacountry.com.br/uploadnew/aniversario/${item.id_tb_aniversario}a.${item.c_extensao}`}
            />
          </a>
        ))}
      </Slider>

      <Slider className="SliderAniversarioResp" {...settings}>
        {dados.map((item: any, index) => (
          <a target="_blank" rel="noreferrer" href={item.c_link} key={index}>
            <img
              alt="Banner Evento"
              src={`https://appvillacountry.com.br/uploadnew/aniversario/${item.id_tb_aniversario}b.${item.c_extensao1}`}
            />
          </a>
        ))}
      </Slider>

      {/* 
        <S.BgAniversario>
          <S.ForaAniversario>
            <S.ItemAniversario>
              <img alt='Ícone Aniversário' src={IconAniversario} />
              <div className='ContentAniversario'>
                <div className='TextAniversario1'>Aniversários</div>
                <div className='TextAniversario2'>Reúna seus amigos e comemore no Villa Country</div>
                <a target='_blank' rel="noreferrer" href='https://wa.me/5511987240123'>Comemore conosco</a>
              </div>
            </S.ItemAniversario>
          </S.ForaAniversario>
        </S.BgAniversario>
      */}
    </>
  );
}

export default withRouter(Aniversario);
