import styled from 'styled-components';
import ImgBgSite from "../../Images/BgSite.jpg";

export const BgShows = styled.section`
    background-color: #131313;
`;

export const ForaShows = styled.div`
    width: 90%;
    margin: 0 auto;
    padding-bottom: 65px;

    .VerShows {
        font-weight: bold;
        font-size: 19px;
        background-color: #fc551e;
        width: 220px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        height: 38px;
        text-transform: uppercase;
        margin-top: 50px;
        cursor: pointer;
    }

    @media (max-width: 650px) {
        padding-bottom: 30px;
    }
`;

export const TopShows = styled.div`
    font-family: 'Poppins', sans-serif;
    color: #fc551e;
    width: 550px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 40px;

    .RiskShows {
        width: 60px;
        height: 2px;
        background-color: #fc551e;
    }

    .TextShows {
        font-weight: bold;
        font-size: 25px;
    }

    @media (max-width: 650px) {
        width: 95%;
        padding-top: 0px;
        padding-bottom: 15px;

        .TextShows {
            font-size: 18px;
        }

        .RiskShows {
            width: 30px;
        }
    }
`;

export const ContentShows = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 985px) {
        justify-content: space-between;
    }  
`;

export const ItemShows = styled.a`
    width: calc(33% - 1%);
    margin-right: 2%;
    margin-bottom: 20px;
    -webkit-text-decoration: none;
    text-decoration: none;

    :nth-child(3n) {
        margin-right: 0;
    }

    .FotoShows {
        width: 100%;
        height: 15vw;
        background-position: center;
        background-size: cover;
    }

    .BaixoShows {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 25px;
    }

    .TextShows1 {
        font-size: 20px;
        font-weight: bold;
        color: #b9b9b9;
        width: 78%;
    }    
    
    .ButtonIngressos {
        font-size: 13px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        background-color: #fc551e;
        width: 105px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        height: 22px;
    }    
    
    .TextShows2 {
        font-size: 20px;
        font-weight: 300;
        color: #b9b9b9;
        width: 100%;
        padding-bottom: 5px;
    }    
    
    .TextShows3 {
        font-size: 15px;
        font-weight: 300;
        color: #fc551e;
        width: 100%;
        text-transform: uppercase;
    }

    @media (max-width: 1384px) {
        width: 32%;

        .TextShows1 {
            font-size: 18px;
            width: 70%;
        }
    }   
    
    @media (max-width: 1100px) {
        .BaixoShows {
            padding-top: 15px;
        }
    }   
    
    @media (max-width: 985px) {
        width: calc(48% - 0%);
        margin-right: 0%;

        .FotoShows {
            height: 24vw;
        }
    }    
    
    @media (max-width: 700px) {
        .ButtonIngressos {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .TextShows1 {
            width: 100%;
        }

        .TextShows2 {
            font-size: 16px;
        }    

        .ButtonIngressos {
            width: 90px;
            font-size: 11px;
            height: 17px;
        }
    }
`;
